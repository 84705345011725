import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import type { Document } from "@contentful/rich-text-types";
import { InfoCircledIcon } from "@radix-ui/react-icons";

import { P } from "~/components/ui/text";
import {
  ABSOLUTE_POSITION,
  ITEMS_POSITION,
  JUSTIFY_POSITION,
  TEXT_ALIGN,
} from "~/lib/constants";
import { cn } from "~/lib/ui";

import { isTypeContentTileTitle, isTypeRichText } from "../compiled";
import type { ImageWithCTAContent } from "../types";
import { getMaxWidthClass, textAlignmentClasses } from "../util";
import { ContentfulCTA } from "./contentful-cta";
import { ContentfulPicture } from "./picture";
import { RichTextRender } from "./rich-text";
import TooltipWithRichText from "./tooltip-with-rich-text";

export const ContentfulImageWithCTA = ({
  content,
  className = "",
  buttonClassname = "",
  textClassname = "",
  isBanner = false,
}: {
  content: ImageWithCTAContent;
  className?: string;
  buttonClassname?: string;
  textClassname?: string;
  isBanner?: boolean;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();
  if (!fields.image) return null;

  // Get the Tailwind class for maxWidth
  const maxWidthClass = getMaxWidthClass(fields.maxWidth);

  return (
    <div className={`${className} ${maxWidthClass} mx-auto`}>
      <div className="relative flex h-full w-full flex-col">
        <div
          className={"w-full rounded-lg"}
          {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
        >
          <ContentfulPicture
            image={fields.image}
            className={className}
            isBanner={isBanner}
          />
        </div>
        <div
          className={cn(
            "mt-[15px] h-full",
            fields.isContentOverlay &&
              `absolute ${
                ABSOLUTE_POSITION[fields.contentPosition]
              } m-auto mt-0 w-fit`,
          )}
        >
          <div
            className={cn(
              `flex h-full flex-col gap-2 md:gap-3 lg:gap-4`,
              fields.isContentOverlay &&
                `items-center ${
                  isBanner
                    ? "justify-end gap-[30px] pb-20 lg:pb-32"
                    : "justify-center"
                } text-center`,
            )}
          >
            <div
              className={cn(
                `flex flex-col ${JUSTIFY_POSITION[fields.contentPosition]} ${
                  ITEMS_POSITION[fields.contentPosition]
                }`,
              )}
            >
              {fields.richText && (
                <div className="w-full max-w-xl">
                  {fields.richText &&
                    fields.richText.map(richContent =>
                      isTypeContentTileTitle(richContent) ? (
                        <div
                          key={richContent.sys.id}
                          className="flex flex-col items-start gap-2"
                        >
                          {"title" in richContent.fields && (
                            <P
                              className={cn(
                                "w-full font-roboto text-xl font-medium leading-8 md:text-xl lg:text-2xl ",
                                textAlignmentClasses[
                                  richContent.fields.textAlignment ?? "left"
                                ],
                              )}
                            >
                              {richContent.fields.title as string}
                            </P>
                          )}
                          {"description" in richContent.fields && (
                            <P
                              className={cn(
                                "w-full text-base",
                                textAlignmentClasses[
                                  richContent.fields.textAlignment ?? "left"
                                ],
                              )}
                            >
                              {richContent.fields.description as string}
                            </P>
                          )}
                        </div>
                      ) : isTypeRichText(richContent) ? (
                        <RichTextRender
                          key={richContent.sys.id}
                          content={richContent.fields.richText as Document}
                          entryId={richContent.sys.id}
                          color={
                            richContent.fields.textColour as string | undefined
                          }
                          textAlignment={
                            richContent.fields.textAlignment as
                              | CanvasTextAlign
                              | undefined
                          }
                          textClassname={textClassname}
                        />
                      ) : null,
                    )}
                </div>
              )}
            </div>

            <div
              className={cn(
                `flex flex-col gap-2 md:gap-3 lg:gap-4 ${
                  JUSTIFY_POSITION[fields.contentPosition]
                } ${ITEMS_POSITION[fields.contentPosition]}`,
              )}
            >
              {!isBanner && fields.toolTipLabel && fields.tooltip && (
                <div
                  className={`${
                    fields.richText && ""
                  } inline-flex items-center gap-1 text-neutral-3`}
                >
                  <TooltipWithRichText
                    content={
                      <RichTextRender
                        content={fields.tooltip}
                        entryId={fields.tooltip.data.id}
                      />
                    }
                  >
                    {/* The Icon is set as children, so we can use everything we want as a trigger for the Tooltip */}
                    <InfoCircledIcon
                      height={16.67}
                      width={16.67}
                      className="cursor-pointer"
                      color="#848484"
                    />
                  </TooltipWithRichText>
                  <p
                    className={cn(
                      "w-fit text-sm font-bold text-neutral-3",
                      TEXT_ALIGN[fields.contentPosition],
                    )}
                  >
                    {fields.toolTipLabel}
                  </p>
                </div>
              )}

              <div
                className={cn(
                  `flex w-full max-w-xl flex-col flex-wrap gap-4 md:flex-row ${
                    JUSTIFY_POSITION[fields.contentPosition]
                  } ${ITEMS_POSITION[fields.contentPosition]}`,
                  fields.isContentOverlay &&
                    "items-center justify-center text-center",
                )}
              >
                {fields.cta &&
                  fields.cta.map(cta => (
                    <ContentfulCTA
                      key={cta.sys.id}
                      content={cta}
                      className={buttonClassname}
                      {...inspectorProps({
                        entryId: content.sys.id,
                        fieldId: "name",
                      })}
                    />
                  ))}
              </div>
            </div>
          </div>
          {isBanner && fields.toolTipLabel && fields.tooltip && (
            <div
              className={`absolute inset-x-0 bottom-4 inline-flex items-center justify-center gap-1 text-neutral-3 md:bottom-3`}
            >
              <TooltipWithRichText
                content={
                  <RichTextRender
                    content={fields.tooltip}
                    entryId={fields.tooltip.data.id}
                  />
                }
              >
                {/* The Icon is set as children, so we can use everything we want as a trigger for the Tooltip */}
                <InfoCircledIcon
                  height={16.67}
                  width={16.67}
                  className="cursor-pointer"
                  color="#141414"
                />
              </TooltipWithRichText>
              <p className="w-fit text-xs font-bold text-brand-primary-black">
                {fields.toolTipLabel}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
