import type { Entry } from "contentful";

import type { SerializeFrom } from "@remix-run/cloudflare";

import type { Options, RenderNode } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

import Text, { H1, H2, H3, H4 } from "~/components/ui/text";

import type {
  TypeCtaSkeleton,
  TypeIconListSkeleton,
  TypeImageSkeleton,
} from "./compiled";
import { ContentfulCTA } from "./components/contentful-cta";
import { ContentfulImage } from "./components/contentful-image";
import { IconList } from "./components/icon-list";
import { ContentfulPicture } from "./components/picture";

export const CONTENT_TYPES = {
  video: "video/mp4",
};

type CTAProps = SerializeFrom<
  Entry<TypeCtaSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
>;
export type ImageProps = SerializeFrom<
  Entry<TypeImageSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
>;
type IconListProps = SerializeFrom<
  Entry<TypeIconListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
>;

const renderEmbeddedEntry = (entry: Entry<any>) => {
  switch (entry.sys.contentType.sys.id) {
    case "cta":
      return <ContentfulCTA content={entry as CTAProps} className="my-5" />;
    case "image":
      return <ContentfulPicture image={entry as ImageProps} className="my-5" />;
    case "iconList":
      return (
        <div className="mt-6">
          <IconList content={entry as IconListProps} />
        </div>
      );
    default:
      return null;
  }
};

export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const entry = node.data.target;
      return renderEmbeddedEntry(entry);
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { file, title } = node.data.target.fields;
      switch (file?.contentType) {
        case CONTENT_TYPES.video:
          return (
            <video
              src={file?.url}
              controls
              autoPlay={true}
              loop
              muted
              playsInline
              className="my-5"
            />
          );
        default:
          return (
            <ContentfulImage
              alt={title}
              imageUrl={file?.url}
              maxWidth="full"
              className="my-5"
            />
          );
      }
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <H1>{children}</H1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <H2>{children}</H2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <H3>{children}</H3>;
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <H4>{children}</H4>;
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className="text-2xl">{children}</h5>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <h6 className="text-lg">{children}</h6>;
    },
    [BLOCKS.TABLE]: (node, children) => (
      <table className="my-5">
        <tbody>{children}</tbody>
      </table>
    ),
    [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children) => (
      <th className="border border-neutral-3 bg-neutral-6 p-5">{children}</th>
    ),
    [BLOCKS.TABLE_CELL]: (node, children) => (
      <td className="border border-neutral-3 p-5">{children}</td>
    ),
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className="my-5 list-inside list-decimal">{children}</ol>;
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className="my-5 list-inside list-disc">{children}</ul>;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li className="[&>p]:inline">{children}</li>;
    },
  },
} satisfies Options;

const robotoHeadings = {
  [BLOCKS.HEADING_1]: (node, children) => {
    return (
      <Text
        as={"h1"}
        className="scroll-m-20 text-[46px] font-bold leading-md lg:text-[82px] "
      >
        {children}
      </Text>
    );
  },
  [BLOCKS.HEADING_2]: (node, children) => {
    return (
      <Text
        as={"h2"}
        className="scroll-m-20 text-[42px] font-bold leading-md lg:text-[64px]"
      >
        {children}
      </Text>
    );
  },
  [BLOCKS.HEADING_3]: (node, children) => {
    return (
      <Text
        as={"h3"}
        className="scroll-m-20 text-[32px] font-bold leading-md lg:text-[48px] "
      >
        {children}
      </Text>
    );
  },
  [BLOCKS.HEADING_4]: (node, children) => {
    return (
      <Text
        as={"h4"}
        className="scroll-m-20 text-[28px] font-bold leading-md lg:text-[36px]"
      >
        {children}
      </Text>
    );
  },
  [BLOCKS.HEADING_5]: (node, children) => {
    return (
      <Text
        as={"h5"}
        className="scroll-m-20 text-[24px] font-bold leading-md lg:text-[32px]"
      >
        {children}
      </Text>
    );
  },
  [BLOCKS.HEADING_6]: (node, children) => {
    return (
      <Text
        as={"h6"}
        className="scroll-m-20 text-[22px] font-bold leading-md lg:text-[26px]"
      >
        {children}
      </Text>
    );
  },
} satisfies RenderNode;

export const robotoOptions = {
  ...options,
  renderNode: {
    ...options.renderNode,
    ...robotoHeadings,
  },
} satisfies Options;

export const maxWidthClasses = {
  768: "max-w-768",
  1280: "max-w-1280",
  1920: "max-w-1920",
  2560: "max-w-2560",
};

export const getMaxWidthClass = (width?: 768 | 1280 | 1920 | 2560) =>
  width ? maxWidthClasses[width] ?? "max-w-full" : "max-w-full";

export const textAlignmentClasses = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};
