import type { ImageProps } from "../util";
import { ContentfulImage } from "./contentful-image";

export const ContentfulPicture = ({
  image,
  className = "",
  isBanner = false,
}: {
  image: ImageProps;
  className?: string;
  isBanner?: boolean;
}) => {
  return (
    <ContentfulImage
      lazy={isBanner ? false : true}
      imageUrl={image?.fields.image?.fields.file?.url ?? ""}
      mobileImageUrl={image?.fields?.mobileImage?.fields.file?.url ?? ""}
      alt={image.fields.alt || ""}
      maxWidth={isBanner ? "full" : "1/2"}
      className={className}
    />
  );
};
